import React from 'react'
import Image from '~/components/atoms/Image'
import TextLink from '~/components/atoms/TextLink'
import TrustPilotWidget from '~/components/atoms/TrustPilotWidget'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Testimonial({ className, ...section }) {

  let reverse = false

  if(section.alignImage === 'left'){
    reverse = true
  }

  const quoteTagType = section?.quoteTagType
  const QuoteTag = quoteTagType && quoteTagType.substr(0, 1) === "h" ? quoteTagType : "h3";

  return (
    <div className={`${section.innerBackgroundColour === 'rose' ? 'bg-rose text-navy' : 'bg-navy text-white'} px-8 md:px-14 py-20 xl:px-32 md:py-28 ${className}`}>
      <div className="lg:grid grid-cols-12 items-center max-w-xl lg:max-w-none mx-auto lg:mx-0">
        <FadeInWhenVisible className={`col-span-5  ${!reverse && 'col-start-8 md:order-1'} mb-12 md:mb-24 lg:mb-0`}>
          <div className="w-full" style={{ clipPath: 'polygon(0% 100%, 100% 100%, 100% 35%, 50% 0%, 0% 35%)' }}>
            <div className="aspect-w-9 aspect-h-10">
              <Image data={section.image} className="!absolute w-full h-full" />
            </div>
          </div>
        </FadeInWhenVisible>
        <div className={`col-span-6 col-start-7 ${!reverse && 'col-start-1'}`}>
          <div className="text-center lg:text-left">
            <FadeInWhenVisible>
              {(section.quote && QuoteTag) && <QuoteTag className={`text-${QuoteTag} testimonial-quote mb-8`}>{section.quote}</QuoteTag> }
            </FadeInWhenVisible>
            <FadeInWhenVisible transition={{delay: '.05'}} className="font-bold">{section.author?.name}</FadeInWhenVisible>
            <FadeInWhenVisible transition={{delay: '.1'}} className="text-current text-opacity-70">{section.author?.role}</FadeInWhenVisible>
          </div>
          {!section.hidetrustpilotwidget && (
            <FadeInWhenVisible transition={{delay: '.15'}}>
              <TrustPilotWidget className="block mt-12" />
            </FadeInWhenVisible>
          )}
          {section.link && (
            <FadeInWhenVisible transition={{delay: '.2'}} className="lg:block flex justify-center mt-10">
              <TextLink {...section.link} lineClassName={`${section.backgroundColour !== 'rose' && '!bg-yellow'} opacity-100`} />
            </FadeInWhenVisible>
          )}
          
        </div>
      </div>
    </div>
  )
}
