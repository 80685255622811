import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
import Testimonial from '~/components/molecules/Testimonial';
import TextCard from '~/components/molecules/TextCard';
import TrustPilotWidget from '~/components/atoms/TrustPilotWidget';
import CustomCursor from '~/components/atoms/CustomCursor';
import FadeInWhenVisible from '~/components/FadeInWhenVisible';

export default function TestimonialsSlider({ key, index, ...props }) {
  const section = props.testimonialsSlider;

  SwiperCore.use([Scrollbar]);

  return (
    <section id={section.fieldGroupName + index}
      className={`${section.margin.top !== 'none' && 'mt-20 md:mt-36 '} ${section.margin.bottom !== 'none' && 'mb-20 md:mb-36 '
        } py-20 md:py-24 service-swiper overflow-hidden bg-navy text-white`}
    >
      <div className="container">
        <header className="grid items-center grid-cols-12 mb-16">
          <TextCard
            {...section.textCard}
            className="col-span-12 lg:col-span-6"
            headingClassName="text-center md:text-left"
          />
          <FadeInWhenVisible className="col-span-12 mt-10 lg:col-span-5 lg:col-start-8 md:mt-0">
            <TrustPilotWidget />
          </FadeInWhenVisible>
        </header>
        <CustomCursor content="Drag" bgColor={section.cursorBackgroundColour}>
          <Swiper
            className="flex flex-col w-full swiper-dark"
            slidesPerView={`auto`}
            grabCursor="1"
            freeMode="1"
            scrollbar={{ draggable: true }}
            style={{ overflow: `visible` }}
          >
            {section?.testimonials?.length > 0 && section.testimonials.map((item, i) => (
              <SwiperSlide className="max-w-[80%] mr-8 last:mr-0" key={`swiper${i}`} style={{ height: 'auto' }}>
                <Testimonial
                  className="!bg-white !text-navy px-4 py-10 md:py-16 xl:py-16 xl:px-20"
                  {...item.testimonial}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </CustomCursor>
      </div>
    </section>
  );
}
